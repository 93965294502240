import * as React from "react";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import { SiteLayout } from "../components/SiteLayout";
import { Seo } from "../components/Seo";

const PageNotFoundPage = () => (
  <SiteLayout>
    <section className="bg-white text-slate-700 px-10 md:px-16 py-12 md:p-24">
      <div className="container mx-auto max-w-5xl">
        <h1>Diese Seite konnte nicht gefunden werden</h1>
        <p>
          Hier geht's zurück zur <Link href="/">Startseite</Link>.
        </p>
      </div>
    </section>
    <section className="bg-transparent text-white px-10 md:px-16 py-12 md:p-24">
      <div className="container mx-auto max-w-5xl">
        <blockquote className="text-inherit mt-6 mb-3 sm:mt-12 sm:mb-5 first:mt-0 tracking-wider leading-relaxed font-lexend text-3xl sm:text-5xl text-center">
          "Denn der Menschensohn ist gekommen, zu suchen und selig zu machen,
          was verloren ist."
        </blockquote>
        <figcaption className="text-center">
          <cite>Lukas 19:10</cite>
        </figcaption>
      </div>
    </section>
  </SiteLayout>
);

export const Head = ({ location: { pathname } }) => (
  <Seo title="Seite nicht gefunden" relativePageUrl={pathname} />
);

export default PageNotFoundPage;
